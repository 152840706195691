import React from "react";
import Renseignement from "./renseignement";
import logo from "../images/logo.png";

export default function Contact() {
  return (
    <div className="pop-up-contact">
      <div className="group-contact">
        <div className="overlap-group">
          <div className="frame">
            <div className="card" />

            <div className="div">06.00.00.00.00</div>

            <div className="text-wrapper-2">Contact</div>

            <div className="text-wrapper-3">julien@gmail.com</div>
            <div className="un-renseignement-instance">
              <div className="label">
                <div className="un-renseignement">Un Renseignement ?</div>
              </div>
            </div>
          </div>

          <img className="logo" alt="Logo" src={logo} />
        </div>
      </div>
    </div>
  );
}
