import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import logo from "../images/logo.png";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import ValInfo from "./valInfo";
export default function Info() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  function handleclick() {
    navigate("/");
  }
  return (
    <>
      <div className="pop-up-renseignement">
        <img className="logo" alt="Logo" src={logo} />
        <div className="div">
          <FontAwesomeIcon
            className="basil-cross-outline"
            icon={faRectangleXmark}
            onClick={() => handleclick()}
          />
          <p className="p">Un renseignement ou une question?</p>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Address Email de contact</Form.Label>
            <Form.Control type="email" placeholder="exemple@gmail.com" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Question, demande de renseignement</Form.Label>
            <Form.Control
              type="textarea"
              placeholder="Informations conplémentaires "
              as="textarea"
              rows={9}
            />
          </Form.Group>
          <div className="bouton-envoyer-instance">
            <div className="bouton-r-server" onClick={handleShow}>
              <div className="text-wrapper">Envoyer</div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        //onHide={handleClose}
        animation={false}
        //size="lg"
        //centered
      >
        <ValInfo />
        <FontAwesomeIcon
          className="basil-cross-outline"
          icon={faRectangleXmark}
          onClick={handleClose}
        />
      </Modal>
    </>
  );
}
