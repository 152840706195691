import React from "react";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRectangleXmark,
  faClock,
  faCalendarDays,
  faVialCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../images/logo.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import RecapForm from "./RecapForm";
import ValForm from "./valForm";
function FormBis() {
  const [show, setShow] = useState(false);
  const [showVal, setShowVal] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef();
  const inputs = useRef([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseVal = () => handleclick();
  const handleShowVal = () => setShowVal(true);
  function handleclick() {
    formRef.current.reset();
    inputs.current.length = 0;
    navigate("/");
  }

  console.log(inputs);
  console.log(formRef);

  async function handleSubmit(event) {
    event.preventDefault();
    let userInfo;
    const url = `${process.env.REACT_APP_BAC_URL}/mail/new-reservation`;
    const token = "1654551514hhgfhfb45665454";
    console.log(inputs);
    console.log(formRef);
    console.log(process.env);

    userInfo = {
      nom: formRef.current[0].value,
      prenom: formRef.current[1].value,
      email: formRef.current[2].value,
      phone: formRef.current[3].value,
      heure: formRef.current[4].value,
      date: formRef.current[5].value,
      passager: formRef.current[6].value,
      attente: formRef.current[7].value,
      info: formRef.current[18].value,
      adresseDepart: formRef.current[9].value,
      villeD: formRef.current[10].value,
      cPostalD: formRef.current[11].value,
      complementD: formRef.current[12].value,
      adresseArrive: formRef.current[14].value,
      villeA: formRef.current[15].value,
      cPostalA: formRef.current[16].value,
      complementA: formRef.current[17].value,
    };

    const data = JSON.stringify(userInfo);

    const response = await fetch(url, {
      method: "POST", //ou POST, PUT, DELETE, etc.
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      body: data,
    });

    if (response.status === 200) {
      handleClose();
      handleShowVal();
      formRef.current.reset();
      inputs.current.length = 0;
    } else {
      alert("Une erreur est survenue");
    }
  }

  return (
    <>
      <div className="formulaire-rsa">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <img className="logoForm" alt="Logo" src={logo} />
          <p className="formulaire-de-r">
            <span className="span">
              Formulaire de réservation en ligne
              <br />
            </span>

            <span className="text-wrapper-2">
              <br />
              Veuillez remplir tous les champs obligatoires* pour valider la
              réservation.
              <br />
            </span>

            <span className="text-wrapper-3">
              Une question ou un renseignement? Veuillez me contacter au
            </span>

            <span className="text-wrapper-4"> 06.00.00.00.00</span>
          </p>
          <Row>
            <Col>
              <Form.Label>Nom*</Form.Label>
              <Form.Control placeholder="Nom" />
            </Col>
            <Col>
              <Form.Label>Prénom*</Form.Label>
              <Form.Control placeholder="Prénom" />
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Addresse Email*</Form.Label>
            <Form.Control type="email" placeholder="exemple@gmail.com" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPhone">
            <Form.Label>Numéro de téléphone*</Form.Label>
            <Form.Control type="phone" placeholder="06 00 00 00 00" />
          </Form.Group>

          <Row>
            <Col>
              <Form.Label>Heure de départ*</Form.Label>
              <Form.Control type="time" className="modalTextField" />
            </Col>
            <Col>
              <Form.Label>Date de départ*</Form.Label>
              <Form.Control type="date" className="modalTextField" />
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Label>Nombres de passagers*</Form.Label>
              <Form.Control />
            </Col>
            <Col>
              <Form.Label>Temps d'attente </Form.Label>
              <Form.Control />
            </Col>
          </Row>
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Adresse ou lieu de départ*</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Addresse* </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="123 chemin des chauffeurs"
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Label>Ville*</Form.Label>
                    <Form.Control placeholder="Nice" />
                  </Col>
                  <Col>
                    <Form.Label>Code Postal* </Form.Label>
                    <Form.Control placeholder="06000" />
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Complément d'adresse </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="3 portail sur votre gauche ..."
                  />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Adresse ou lieu d'arrivée*</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Addresse* </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="123 chemin des chauffeurs"
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Label>Ville*</Form.Label>
                    <Form.Control placeholder="Nice" />
                  </Col>
                  <Col>
                    <Form.Label>Code Postal* </Form.Label>
                    <Form.Control placeholder="06000" />
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Complément d'adresse </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="3 portail sur votre gauche ..."
                  />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Form.Group
            id="text-area-comment"
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>Informations conplémentaires</Form.Label>
            <Form.Control
              type="textarea"
              placeholder="Informations conplémentaires "
              as="textarea"
              rows={3}
            />
          </Form.Group>
          <div className="bouton-envoyer-instance">
            <Button
              variant="primary"
              className="bouton-r-server"
              onClick={handleShow}
            >
              <p className="text-wrapper">Envoyer</p>
            </Button>
          </div>
        </Form>
        <FontAwesomeIcon
          className="basil-cross-outline"
          icon={faRectangleXmark}
          onClick={() => handleclick()}
        />
      </div>
      <Modal
        show={show}
        //onHide={handleClose}
        animation={false}
        size="lg"
        //centered
      >
        <RecapForm inputs={formRef} />
        <FontAwesomeIcon
          className="basil-cross-outline"
          icon={faRectangleXmark}
          onClick={handleClose}
        />
        <div className="bouton-modifier-instance">
          <Button
            variant="primary"
            type="submit"
            className="bouton-r-server"
            onClick={handleClose}
          >
            <p className="text-wrapper">Modifier</p>
          </Button>
        </div>
        <div className="bouton-valider-instance">
          <Button
            variant="primary"
            type="submit"
            className="bouton-r-server"
            onClick={handleSubmit}
          >
            <p className="text-wrapper">Valider</p>
          </Button>
        </div>
      </Modal>
      <Modal
        show={showVal}
        //onHide={handleClose}
        animation={false}
        //size="lg"
        //centered
      >
        <ValForm />
        <FontAwesomeIcon
          className="basil-cross-outline"
          icon={faRectangleXmark}
          onClick={handleCloseVal}
        />
      </Modal>
    </>
  );
}

export default FormBis;
