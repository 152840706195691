import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
function RecapResa(props) {
  const reservationList = props.reservationList;
  console.log(reservationList);

  return (
    <div className="formulaire-rsa">
      <Form>
        <p className="formulaire-de-r2">
          <span className="text-wrapper-2">
            <br />
            Récapitulatif de la réservation
            <br />
            Statut: {reservationList.state}
          </span>
        </p>

        <Row>
          <Col>
            <Form.Label>Nom: {reservationList.nom}</Form.Label>
          </Col>
          <Col>
            <Form.Label>Prénom:{reservationList.prenom} </Form.Label>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Addresse Email: {reservationList.email} </Form.Label>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPhone">
          <Form.Label>Numéro de téléphone: {reservationList.phone}</Form.Label>
        </Form.Group>

        <Row>
          <Col>
            <Form.Label>Heure de départ: {reservationList.heure}</Form.Label>
          </Col>
          <Col>
            <Form.Label>Date de départ:{reservationList.dateD} </Form.Label>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Label>
              Nombre de passagers: {reservationList.passager}
            </Form.Label>
          </Col>
          <Col>
            <Form.Label>Temps d'attente: {reservationList.attente}</Form.Label>
          </Col>
        </Row>
        <Accordion defaultActiveKey="0">
          <Accordion.Item id="recap" eventKey="0">
            <Accordion.Header>Adresse ou lieu de départ</Accordion.Header>
            <Accordion.Body>
              <Form.Group className="mb-3">
                <Form.Label>
                  Addresse:{reservationList.adresseDepart}
                </Form.Label>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Label>Ville:{reservationList.villeD} </Form.Label>
                </Col>
                <Col>
                  <Form.Label>
                    Code Postal: {reservationList.cPostalD}
                  </Form.Label>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  Complément d'adresse:{reservationList.complementD}
                </Form.Label>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
          <Accordion.Item id="recap" eventKey="0">
            <Accordion.Header>Adresse ou lieu d'arrivée</Accordion.Header>
            <Accordion.Body>
              <Form.Group className="mb-3">
                <Form.Label>
                  Addresse: {reservationList.adresseArrive}
                </Form.Label>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Label>Ville:{reservationList.villeA}</Form.Label>
                </Col>
                <Col>
                  <Form.Label>
                    Code Postal:{reservationList.cPostalA}
                  </Form.Label>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  Complément d'adresse: {reservationList.complementA}
                </Form.Label>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            Informations conplémentaires: {reservationList.info}
          </Form.Label>
        </Form.Group>
      </Form>
    </div>
  );
}

export default RecapResa;
