import React from "react";
import logo from "../images/logo.png";

export default function Map() {
  return (
    <div className="menu-card-map">
      <div className="gis-road-map" />

      <div className="group-contact">
        <div className="overlap-group">
          <div className="card" />

          <div className="cannes-nice-saint">
            Cannes
            <br />
            Nice <br />
            Saint-Laurent-Du-Var
            <br />
            Cagne-Sur-Mer
            <br />
            Antibes
            <br />
            Grasse
            <br />
            Mandelieu
            <br />
            Proximité Monaco&nbsp;&nbsp;
            <br />
            Var (83)
          </div>
        </div>
      </div>

      <img className="logo" alt="Logo" src={logo} />
    </div>
  );
}
