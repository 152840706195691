import React from "react";
import { Link } from "react-router-dom";
//import { Link } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
//import { useNavigate } from "react-router-dom";
function Footer() {
  return (
    <footer>
      <Link
        to="/cgu"
        style={{
          textDecoration: "underline",
          color: "whitesmoke",
          marginRight: 5,
        }}
      >
        CGU
      </Link>
      <FontAwesomeIcon className="copyright" icon={faCopyright} />
      <h5>2024/2025 Robello Corporate</h5>
    </footer>
  );
}

export default Footer;
