import React from "react";

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRectangleXmark,
  faClock,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../images/logo.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
function RecapForm(props) {
  const navigate = useNavigate();
  function handleclick() {
    navigate("/");
  }
  console.log(props.inputs.current);

  return (
    <>
      <div className="formulaire-rsa">
        <Form>
          <img className="logoForm" alt="Logo" src={logo} />
          <p className="formulaire-de-r2">
            <span className="text-wrapper-2">
              <br />
              Veuillez vérifier tous les champs avant de valider la réservation.
              <br />
            </span>
          </p>
          <Row>
            <Col>
              <Form.Label>Nom: {props.inputs.current[0].value}</Form.Label>
            </Col>
            <Col>
              <Form.Label>
                Prénom:<p>{props.inputs.current[1].value}</p>{" "}
              </Form.Label>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              Addresse Email: {props.inputs.current[2].value}{" "}
            </Form.Label>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPhone">
            <Form.Label>
              Numéro de téléphone: {props.inputs.current[3].value}
            </Form.Label>
          </Form.Group>

          <Row>
            <Col>
              <Form.Label>
                Heure de départ: {props.inputs.current[4].value}
              </Form.Label>
            </Col>
            <Col>
              <Form.Label>
                Date de départ:{props.inputs.current[5].value}{" "}
              </Form.Label>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Label>
                Nombre de passagers: {props.inputs.current[6].value}
              </Form.Label>
            </Col>
            <Col>
              <Form.Label>
                Temps d'attente: {props.inputs.current[7].value}
              </Form.Label>
            </Col>
          </Row>
          <Accordion defaultActiveKey="0">
            <Accordion.Item id="recap" eventKey="0">
              <Accordion.Header>Adresse ou lieu de départ</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Addresse:{props.inputs.current[9].value}{" "}
                  </Form.Label>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Label>
                      Ville:{props.inputs.current[10].value}{" "}
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>
                      Code Postal: {props.inputs.current[11].value}
                    </Form.Label>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Complément d'adresse:{props.inputs.current[12].value}{" "}
                  </Form.Label>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <Accordion.Item id="recap" eventKey="0">
              <Accordion.Header>Adresse ou lieu d'arrivée</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Addresse: {props.inputs.current[14].value}{" "}
                  </Form.Label>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Label>
                      Ville:{props.inputs.current[15].value}{" "}
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>
                      Code Postal:{props.inputs.current[16].value}{" "}
                    </Form.Label>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Complément d'adresse: {props.inputs.current[17].value}
                  </Form.Label>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              Informations conplémentaires: {props.inputs.current[18].value}
            </Form.Label>
          </Form.Group>
        </Form>
      </div>
    </>
  );
}

export default RecapForm;
