import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRectangleXmark,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
export default function ValInfo() {
  return (
    <div className="validation">
      <div className="flexcontainer">
        <p className="text">
          <span className="text-wrapper">
            Votre message a bien été envoyé
            <br />
          </span>
        </p>

        <p className="text">
          <span className="span">
            Vous recevrez une réponse de ma part dans peu de temps
          </span>
        </p>
        <FontAwesomeIcon
          className="flat-color-icons-ok"
          alt="flat Color Icons Ok"
          icon={faCircleCheck}
        />
      </div>
    </div>
  );
}
