import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRectangleXmark,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
function ModifiResaAdmin(props) {
  async function modifyResa(event) {
    event.preventDefault();
    const token = "1654551514hhgfhfb45665454";
    const url = `${process.env.REACT_APP_BAC_URL}/user/modifieuseradmin`;

    const userInfo = {
      state: event.target.elements.state.value,
      email: props.reservationListe.email,
      id: props.reservationListe._id,
      commentaire: props.reservationListe.commentaire,
    };
    const data = JSON.stringify(userInfo);
    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      body: data,
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      alert("Succès: reservation modifiée avec succés");
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }

    return;
  }
  return (
    <div className="validation">
      <div className="flexcontainer">
        <p className="text">
          <span className="text-wrapper">
            Modifier le statut de la réservation N°:
            <br />
          </span>
        </p>
        <Form.Select aria-label="Default select example">
          <option value="En attente de validation">
            En attente de validation
          </option>
          <option value="Validée">Validée</option>
          <option value="Annulée">Annulée</option>
          <option value="Terminée">Terminée</option>
          <option value="Refusée">Refusée</option>
        </Form.Select>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            type="textarea"
            placeholder="Laisser un commentaire "
            as="textarea"
            rows={6}
          />
        </Form.Group>
        <div className="bouton-envoyer-instance">
          <div className="bouton-r-server">
            <div className="text-wrapper">Modifier</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifiResaAdmin;
