import React from "react";
import "./styles/index.scss";
import { Routes, Route } from "react-router-dom";
//import { Routes, Route } from "react-router";

import NotFound from "./components/NotFound";
import Cgu from "./components/cgu";
import Footer from "./components/Footer";
import Form from "./components/form";
import Home from "./components/home";
import Info from "./components/Info";
import Contact from "./components/Contact";
import Map from "./components/map";
import ValForm from "./components/valForm";
import ValInfo from "./components/valInfo";
import FormBis from "./components/formBis";
import ConsulterResa from "./components/consulterResa";
import LinkResa from "./components/linkResa";
import ConsulterResaAdmin from "./components/ConsulterResaAdmin";
import ModifiResaAdmin from "./components/modifiResaAdmin";
function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/réserver" element={<FormBis />} />
        <Route path="/form" element={<FormBis />} />
        <Route path="/map" element={<Map />} />
        <Route path="/consultation-reservation" element={<ConsulterResa />} />
        <Route
          path="/consultation-reservation/admin"
          element={<ConsulterResaAdmin />}
        />
        <Route path="lien-reservation/*" element={<LinkResa />} />
        <Route path="/cgu" element={<Cgu />} />
        <Route path="/info" element={<Info />} />
        <Route path="/validationFormulaire" element={<ValForm />} />
        <Route path="/validationInfo" element={<ValInfo />} />
        <Route path="/modification-reservation" element={<ModifiResaAdmin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
