import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRectangleXmark,
  faCircleCheck,
  faMagnifyingGlass,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../images/logo.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";

import ValForm from "./valForm";
import RecapResa from "./RecapResa";
function ConsulterResaAdmin() {
  const [show, setShow] = useState(false);
  const [reservationList, setReservationList] = useState();
  const [search, setSearch] = useState();
  const [select, setSelect] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(search);
  const navigate = useNavigate();
  function handleclick() {
    setReservationList("");
    // setShow(false);
  }
  function handleclick2() {
    setReservationList("");
    navigate("/");
  }
  async function getReservationList(event) {
    event.preventDefault();
    let resaID;
    const token = "1654551514hhgfhfb45665454";
    const url = `${process.env.REACT_APP_BAC_URL}/mail/all-reservation`;
    console.log(search);

    resaID = {
      reservationId: search,
      headers: "true",
    };
    console.log(resaID);

    const data = JSON.stringify(resaID);

    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },

      body: data,
    };
    const response = await fetch(url, options);

    let result = await response.json();
    console.log(response.status);
    console.log(response.data);
    console.log(response);
    console.log(result);

    if (response.status === 200) {
      setReservationList(result.data);
      console.log(result.data);
    }
  }
  console.log(reservationList);

  async function modifyResa(e, item) {
    e.preventDefault();
    console.log(e);
    console.log(item);

    const token = "1654551514hhgfhfb45665454";
    const url = `${process.env.REACT_APP_BAC_URL}/mail/update-statut`;

    const userInfo = {
      reservationId: search,
      state: e.target.elements.state.value,
      email: item.email,
      id: item._id,
      commentaire: e.target.elements.commentaire.value,
    };
    const data = JSON.stringify(userInfo);
    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      body: data,
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      alert("Succès: reservation modifiée avec succés");
      getReservationList();
      handleClose();
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }

    return;
  }

  return (
    <div className="consulter-resa">
      <img className="logoForm" alt="Logo" src={logo} />

      <div className="resa-admin">
        {reservationList ? (
          <>
            <p className="header-consulter-admin">
              <span>
                <br />
                <h3>Réservations</h3>
                <br />
              </span>
            </p>
            {reservationList.map((item, key) => (
              <div className="formulaire-rsa">
                <Accordion defaultActiveKey="1">
                  <Accordion.Item id="recap" eventKey="0">
                    <Accordion.Header>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          M:{item.prenom}
                          <br></br>
                          {item.nom}{" "}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          le: {item.dateD} à {item.heure}
                        </Form.Label>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>
                          statut: {item.state}{" "}
                          <FontAwesomeIcon
                            className="btn-modify"
                            icon={faPenToSquare}
                            onClick={handleShow}
                          />
                        </Form.Label>
                      </Form.Group>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Form.Group className="mb-3">
                        <Form.Label>Commande passée le: {item.date}</Form.Label>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Addresse de départ: {item.adresseDepart}
                        </Form.Label>
                      </Form.Group>
                      <Row>
                        <Col>
                          <Form.Label>Ville de départ:{item.villeD}</Form.Label>
                        </Col>
                        <Col>
                          <Form.Label>Code Postal:{item.cPostalD}</Form.Label>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Complément d'adresse: {item.complementD}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Addresse d'arrivé: {item.adresseArrive}
                        </Form.Label>
                      </Form.Group>
                      <Row>
                        <Col>
                          <Form.Label>Ville d'arrivé:{item.villeA}</Form.Label>
                        </Col>
                        <Col>
                          <Form.Label>Code Postal:{item.cPostalA}</Form.Label>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Complément d'adresse: {item.complementA}
                        </Form.Label>
                      </Form.Group>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Modal
                  show={show}
                  //onHide={handleClose}
                  animation={false}
                  size="lg"
                  key={key}
                  reservationList={item}
                  //centered
                >
                  <form
                    className="validation"
                    onSubmit={(e) => modifyResa(e, item)}
                  >
                    <div className="flexcontainer">
                      <p className="text">
                        <span className="text-wrapper">
                          Modifier le statut de la réservation N°:
                          <br />
                        </span>
                      </p>
                      <Form.Select
                        aria-label="Default select example"
                        className="select"
                        id="state"
                      >
                        <option value="En attente de validation">
                          En attente de validation
                        </option>
                        <option value="Validée">Validée</option>
                        <option value="Annulée">Annulée</option>
                        <option value="Terminée">Terminée</option>
                        <option value="Refusée">Refusée</option>
                      </Form.Select>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                        id="commentaire-container"
                      >
                        <Form.Control
                          className="test-area"
                          type="textarea"
                          placeholder="Laisser un commentaire "
                          as="textarea"
                          rows={6}
                          id="commentaire"
                          defaultValue="Réservation validée"
                        />
                      </Form.Group>
                      <div className="bouton-envoyer-instance">
                        <button type="submit" className="bouton-r-server">
                          <div className="text-wrapper">Modifier</div>
                        </button>
                      </div>
                    </div>
                  </form>
                  <FontAwesomeIcon
                    className="basil-cross-outline"
                    icon={faRectangleXmark}
                    onClick={handleClose}
                  />
                </Modal>
              </div>
            ))}
          </>
        ) : (
          <div className="engin-array">
            <p className="header-consulter">
              <span>
                <br />
                <h3>Consulter les réservations</h3>
                <br />
                <form
                  className="capteur-title-container"
                  onSubmit={getReservationList}
                >
                  <input
                    id="search"
                    className="search"
                    type="text"
                    placeholder="Mot de passe"
                    onChange={(e) => setSearch(e.target.value)}
                    //  value={search}
                  ></input>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    onClick={getReservationList}
                    className="search-btn"
                  />
                </form>
                <h5>
                  Pour consulter vos réservations, veuillez saisir votre mot de
                  passe dans le champ ci-dessus.
                </h5>
              </span>
            </p>

            <FontAwesomeIcon
              className="basil-cross-outline"
              icon={faRectangleXmark}
              onClick={handleclick2}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default ConsulterResaAdmin;
