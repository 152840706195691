import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRectangleXmark,
  faCircleCheck,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../images/logo.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ValForm from "./valForm";
import RecapResa from "./RecapResa";
function LinkResa() {
  const [reservationList, setReservationList] = useState();
  const [search, setSearch] = useState();
  const [select, setSelect] = useState(false);
  const urlToken = useLocation();
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  function handleclick() {
    setReservationList("");
    navigate("/");
  }
  function handleclick2() {
    setReservationList("");
    navigate("/");
  }

  async function getReservationList() {
    let resaID;
    const token = "1654551514hhgfhfb45665454";
    const url = `${process.env.REACT_APP_BAC_URL}/mail/reservation`;
    console.log(search);
    console.log(search);
    console.log(urlToken);
    console.log(urlToken.pathname.substring(18));
    resaID = {
      reservationId: urlToken.pathname.substring(18),
      headers: "true",
    };
    console.log(resaID);

    const data = JSON.stringify(resaID);

    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },

      body: data,
    };
    const response = await fetch(url, options);

    let result = await response.json();
    console.log(response.status);
    console.log(response.data);
    console.log(response);
    console.log(result);

    if (response.status === 200) {
      setReservationList(result.data);
      console.log(result.data);
    }
  }
  console.log(reservationList);
  useEffect(() => {
    getReservationList();
  }, []);
  return (
    <div className="consulter-resa">
      <img className="logoForm" alt="Logo" src={logo} />

      <>
        {reservationList ? (
          <Modal
            show={show}
            //onHide={handleClose}
            animation={false}
            size="lg"
            //centered
          >
            <RecapResa reservationList={reservationList} />
            <FontAwesomeIcon
              className="basil-cross-outline"
              icon={faRectangleXmark}
              onClick={handleclick}
            />
          </Modal>
        ) : (
          <div className="engin-array">
            <p className="header-consulter">
              <span>
                <br />
                <h3>Consultation des réservations impossible</h3>
                <br />

                <h5>Une erreur s'est produite, veuillez me contacter.</h5>
              </span>
            </p>

            <FontAwesomeIcon
              className="basil-cross-outline"
              icon={faRectangleXmark}
              onClick={handleclick2}
            />
          </div>
        )}
      </>
    </div>
  );
}

export default LinkResa;
