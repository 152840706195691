import React from "react";
import { useState } from "react";
import BoutonRServer from "./boutonRServer";
import Map from "./map";
import Info from "./Info";
import Renseignement from "./renseignement";
import logo from "../images/logo.png";
import voitureTesla2 from "../images/tesla.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  faPhoneVolume,
  faLocationDot,
  faRectangleXmark,
} from "@fortawesome/free-solid-svg-icons";
import Contact from "./Contact";
export default function Home() {
  const navigate = useNavigate();
  function handleclick() {
    navigate("/réserver");
  }
  function handleclick2() {
    navigate("/consultation-reservation");
  }

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  return (
    <>
      <div className="ipad-pro">
        <div className="top-bar">
          <div className="frame-head">
            <div className="menu">
              <nav className="nav-link" onClick={handleclick2}>
                Mes réservations
              </nav>

              <div className="bouton-r-server">
                <div className="text-wrapper" onClick={() => handleclick()}>
                  Réserver
                </div>
              </div>
            </div>

            <div className="logo-wrapper">
              <img className="logo" alt="Logo" src={logo} />
            </div>
          </div>
        </div>

        <div className="frame-2">
          <div className="contactez-moi-pour-r-wrapper">
            <p className="contactez-moi-pour-r">
              CONTACTEZ-MOI POUR RÉSERVER VOTRE TRAJET
            </p>
          </div>

          <img
            className="voiture-tesla"
            alt="Voiture tesla"
            src={voitureTesla2}
          />

          <div className="rectangle" />

          <div className="frame-3">
            <p className="r-server-votre">
              Réserver votre trajet avec Julien, chauffeur <br />
              privé sur la côte d’Azur.
              <br />
              Disponible 7j/7 avec une grande capacité <br />
              de rangement bagages pour vos déplacements <br />
              seul ou en famille.
            </p>

            <div className="bouton-r-server">
              <div className="text-wrapper" onClick={() => handleclick()}>
                Réserver
              </div>
            </div>
          </div>

          <div className="frame-4">
            <div className="group-contact">
              <div className="overlap-group" onClick={handleShow2}>
                <FontAwesomeIcon
                  className="vector"
                  alt="Vector"
                  icon={faPhoneVolume}
                />
                <div className="text-wrapper-5">06.00.00.00.00</div>

                <div className="text-wrapper-6">julien@gmail.com</div>

                <div className="text-wrapper-7">Contact</div>

                <div className="label">
                  <div
                    className="un-renseignement-instance"
                    onClick={handleShow3}
                  >
                    Un Renseignement ?
                  </div>
                </div>
              </div>
            </div>
            <div className="group-contact2">
              <div className="overlap-group" onClick={handleShow}>
                <FontAwesomeIcon
                  className="vector"
                  alt="Vector"
                  icon={faLocationDot}
                />
                <div className="text-wrapper-7">Côte d'azur</div>
                <div className="text-wrapper-6">Aéroport</div>
                <div className="text-wrapper-5">Gare SNCF</div>
                <div className="text-wrapper-4">Var (83)</div>
                <div className="text-wrapper-3">Arrière pays</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="lg"
        //centered
      >
        <Map />
        <FontAwesomeIcon
          className="basil-cross-outline"
          icon={faRectangleXmark}
          onClick={handleClose}
        />
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        animation={false}
        size="lg"
        //centered
      >
        <Contact />
        <FontAwesomeIcon
          className="basil-cross-outline"
          icon={faRectangleXmark}
          onClick={handleClose2}
        />
      </Modal>
      <Modal
        show={show3}
        // onHide={handleClose3}
        animation={false}
        size="lg"
        //centered
      >
        <Info />
        <FontAwesomeIcon
          className="basil-cross-outline"
          icon={faRectangleXmark}
          onClick={handleClose3}
        />
      </Modal>
    </>
  );
}
