import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
//import { BrowserRouter } from "react-router";
import "./styles/index.scss";
import App from "./App";

///initialisation du rooter
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  //appelle d'user provider en prioritée pour
  //rendre le context accésible à toute l'application

  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);
