import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRectangleXmark,
  faCircleCheck,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../images/logo.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";

import ValForm from "./valForm";
import RecapResa from "./RecapResa";
function ConsulterResa() {
  const [show, setShow] = useState(true);
  const [reservationList, setReservationList] = useState();
  const [search, setSearch] = useState();
  const [select, setSelect] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(search);
  const navigate = useNavigate();
  function handleclick() {
    setReservationList("");
    // setShow(false);
  }
  function handleclick2() {
    setReservationList("");
    navigate("/");
  }
  async function getReservationList(event) {
    event.preventDefault();
    let resaID;
    const token = "1654551514hhgfhfb45665454";
    const url = `${process.env.REACT_APP_BAC_URL}/mail/reservation`;
    console.log(search);

    resaID = {
      reservationId: search,
      headers: "true",
    };
    console.log(resaID);

    const data = JSON.stringify(resaID);

    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },

      body: data,
    };
    const response = await fetch(url, options);

    let result = await response.json();
    console.log(response.status);
    console.log(response.data);
    console.log(response);
    console.log(result);

    if (response.status === 200) {
      setReservationList(result.data);
      console.log(result.data);
    }
  }
  console.log(reservationList);

  async function modifyResa(event) {
    event.preventDefault();
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/user/modifieuseradmin`;

    const userInfo = {
      state: event.target.elements.state.value,
    };
    const data = JSON.stringify(userInfo);
    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      body: data,
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      alert("Succès: reservation validée avec succés");
      getReservationList();
      setSelect(false);
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }

    return;
  }
  async function deleteResa(id) {
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/mail/deleteuseradmin`;
    console.log(id);
    const messageId = {
      userId: id,
    };
    const data = JSON.stringify(messageId);
    const options = {
      method: "DELETE",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: data,
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      alert("Succès: reservation supprimée");
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }
  }

  return (
    <div className="consulter-resa">
      <img className="logoForm" alt="Logo" src={logo} />

      <>
        {reservationList ? (
          <Modal
            show={show}
            //onHide={handleClose}
            animation={false}
            size="lg"
            //centered
          >
            <RecapResa reservationList={reservationList} />
            <FontAwesomeIcon
              className="basil-cross-outline"
              icon={faRectangleXmark}
              onClick={handleclick}
            />
          </Modal>
        ) : (
          <div className="engin-array">
            <p className="header-consulter">
              <span>
                <br />
                <h3>Consulter mes réservations</h3>
                <br />
                <form
                  className="capteur-title-container"
                  onSubmit={getReservationList}
                >
                  <input
                    id="search"
                    className="search"
                    type="text"
                    placeholder="N°Réservation"
                    onChange={(e) => setSearch(e.target.value)}
                    //  value={search}
                  ></input>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    onClick={getReservationList}
                    className="search-btn"
                  />
                </form>
                <h5>
                  Pour consulter vos réservations, veuillez saisir votre numéro
                  de réservation dans le champ ci-dessus.
                </h5>
              </span>
            </p>

            <FontAwesomeIcon
              className="basil-cross-outline"
              icon={faRectangleXmark}
              onClick={handleclick2}
            />
          </div>
        )}
      </>
    </div>
  );
}
export default ConsulterResa;
